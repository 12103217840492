<template>
  <div class="content">
    <ApplicationNannyInputEmploymentRegularity
      v-model:employment-regularity="applicationNanny.employment.regularities"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateSchedule' }"
      :isDisabled="!applicationNanny.employment.regularities.length"
    />
  </div>
</template>

<script>
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputEmploymentRegularity from "@/components/Application/Nanny/ApplicationNannyInputEmploymentRegularity.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    ApplicationNannyInputEmploymentRegularity,
    ButtonPrimaryNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    return {
      applicationNanny,
    };
  },
};
</script>

<style scoped>
.content {
  width: 360px;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.wrapper-checkboxes {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
