<template>
  <div class="container">
    <BaseH1 :text="'Mnie pasuje...'" />

    <div class="wrapper-checkboxes">
      <InputCheckboxGroupSticked
        v-model="application.employmentRegularity"
        :name="'employment-regularity'"
        :options="employmentRegularityOptions"
        :columns="1"
      />

      <TextHint :text="'Możesz zaznaczyć kilka opcji'" />
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputCheckboxGroupSticked from "@/components/UI/Base/InputCheckboxGroupSticked.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    TextHint,
    InputCheckboxGroupSticked,
  },

  props: {
    employmentRegularity: Array,
  },

  emits: ["update:employmentRegularity"],

  setup(props, { emit }) {
    const application = reactive({
      employmentRegularity: props.employmentRegularity,
    });

    watch(
      () => application.employmentRegularity,
      (newEmploymentRegularity) =>
        emit("update:employmentRegularity", newEmploymentRegularity)
    );
    watch(
      () => props.employmentRegularity,
      (newEmploymentRegularity) =>
        (application.employmentRegularity = newEmploymentRegularity)
    );

    const employmentRegularityOptions = [
      {
        label: "Dorywczo",
        value: "occasionally",
      },
      {
        label: "Regularnie",
        value: "regularly",
      },
      {
        label: "Jednorazowo",
        value: "baby_sitting",
      },
    ];

    return {
      application,
      employmentRegularityOptions,
    };
  },
};
</script>

<style scoped>
.container {
  width: 360px;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.wrapper-checkboxes {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
